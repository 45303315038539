// fonts
/** Sizes */
$baseFontSize: 18px;
$bp-1k: 120em;
$bp-2k: 160em;

/** Media Query */
$mq-2k: "(min-width: #{$bp-2k})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)";

$fontBase: 'Inria Sans', sans-serif;
$fontTitle: 'Inspiration', cursive;

/** Transition time */
$transitionTime: 0.5s;
$radius: 60px;

// Colors Brand

$brand-primary: #9B8B76;
$brand-secondary: #F2B485;
$brand-third: #838180;
$brand-alternative: #F3C5A4;
$brand-alternative-2: #946C4E;
$back-alternative: #F9D5B8;
$back-gray: #D7E0E6;
$brand-light: white;
$brand-error: red;


//Shadow
$shadow-1: rgba(0, 0, 0, 0.09);

html,
body {
    font-family: $fontBase;
    font-size: em($baseFontSize);
    color: $brand-primary;

    @media #{$mq-2k} {
        font-size: em(16px)*1.3;
    }

    &.noScroll{
        .sdNav{
            opacity: 1;
            visibility: visible;
            transition: opacity 250ms ease, visibility 250ms ease;

        }
        header {

            .sd-hdrMb .hamburger{
                span{
                    background: $brand-primary;
                    width: 30px;

                    &:first-child{
                        transform: translate(0px, 10px) rotate(45deg);
                    }
                    &:nth-child(2){
                        opacity: 0;
                    }
                    &:last-child{
                        transform: rotate(-45deg) translate(7px, -7px);
                    }
                }
            }
            ul{
                color:$brand-primary;
            }
            .sd-cta{
                padding-top: 50px;
                >span{
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
        .btnLined{
            border-color: $brand-primary;
            color:$brand-primary;
            text-align: center;
        }
    }
}

main {
    position: relative;
    overflow: hidden;
}
