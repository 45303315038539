@keyframes pulse {
    10% {
        transform: scale(1.1);
    }
}

/***********************************************************
GENERAL ELEMENTS
***********************************************************/
a:hover {
    color: #191919;
}

a:active,
a:hover {
    text-decoration: none;

}

a[href*="tel"] {
    color: $brand-alternative;
}

a,
button {
    border: none;

    &:focus {
        outline: none;
        border: none;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;
}

//fix slick spaces
.slick-slide {
    line-height: 0;
}

//box whit shadow
.ShadowBox {
    border-radius: 7px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

//show-hide tabs
.hideTab {
    display: none;
}

.showTab {
    display: block;
}

picture {
    width: 100%;
}

//custom Select

.roundSelect {
    min-width: 160px;
    height: 44px;
    border-radius: 22px;
    padding-left: 20px;
    color: $brand-primary;
    -moz-appearance: none;
    appearance: none;

    &.brd-primary {
        border: solid 2px $brand-primary;
    }

    &.brd-secondary {
        border: solid 2px $brand-light;
    }

    &.back-light {
        background: url("/images/arrow-down-s.svg") #f2f2f2 no-repeat 92% center;
    }

    &.back-white {
        background: url("/images/arrow-down-b.svg") #ffffff no-repeat 92% center;
    }

    &.back-primary {
        background: url("/images/arrow-down-w.svg") transparent no-repeat 92% center;
    }
}

select:focus {
    outline: none;
}

.LineSelect {
    border: none;
    border-bottom: 1px solid #c4c4c4;
    border-radius: 0;
    width: 100%;
    padding-right: 20px;
    color: $brand-third;
    -moz-appearance: none;
    appearance: none;

    &.back-white {
        background: url("/images/arrow-down-b.svg") #ffffff no-repeat right center;
    }
}

/***********************************************************
TEXT COLORS
***********************************************************/

.txtColorLight {
    color: $brand-light;
}

.txtColorPrimary {
    color: $brand-primary;
}
.txtColorPrimary-forced {
    color: $brand-primary !important;
}

.txtColorSecondary {
    color: $brand-secondary;
}

.textThin {
    font-weight: lighter;
}

/***********************************************************
BG COLORS
***********************************************************/

.bgColorLight {
    background: $brand-light;
}

.bgColorPrimary {
    background: $brand-primary;
}

.bgColorSecondary {
    background: $brand-secondary;
}

/***********************************************************
ACTIONS
***********************************************************/

.BtnStd {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 30px;
    color: white;
    display: block;
    height: 56px;
    min-width: 120px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    font-size: 26px;
    text-align: center;
    width: 200px;
    transition: all 0.5s ease-out;
    font-family: $fontTitle;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;

    @media #{$mq-2k} {
        min-width: 225px;
    }

    &.disabled {
        background-color: #df1d4d !important;

        .iArrow,
        .iArrow:focus,
        .iArrow:hover {
            display: none !important;

        }
    }

    @include hover {
        color: white;
        background-color: $brand-primary;
    }
}

/***********************************************************
    CONTAINER
    ***********************************************************/
.container {
    @media #{$mq-2k} {
        max-width: 1550px;
    }
}

label {
    &.error {
        color: $brand-error;
    }
}
//estimate
.EstimateForm {
    .EstimateForm-group {
        margin-bottom: 10px;
    }

    label {
        color: $brand-third;
        font-size: em(14px);
        display: block;
        padding-left: 10px;
    }

    select {
        background: url("/images/arrow-down-b.svg") #fbfbfb no-repeat 95% center;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: solid 1px #dedede;
        box-shadow: inset 0 1px 7px 0 rgba(0, 0, 0, 0.13);
        color: $brand-primary;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        font-size: em(22px);
        padding-left: 20px;
        text-transform: uppercase;

        @media #{$mq-2k} {
            font-size: em(16px);
        }
    }

    select:focus {
        outline: none;
    }

    textarea {
        height: 110px;
        max-height: 110px;
        min-height: 110px;
        max-width: 100%;
        min-width: 100%;
        overflow: auto;
        padding-top: 5px;
    }
}