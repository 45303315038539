body {
    &.is_home {
        background-color: white;
    }
}

.in-headband {
    background-color: $back-alternative;
    padding-bottom: 48px;
    padding-top: 48px;
    position: relative;

    .decoration {
        background-color: $brand-alternative-2;
        height: 2px;
        position: absolute;
        width: 100%;

        &.up {
            top: 25px;
        }

        &.down {
            bottom: 25px;
        }

        img {
            margin: 0 auto;
            display: block;
            height: 15px;
            width: 15px;
            top: -7px;
            position: relative;
        }
    }

    .wrap-text {
        .title-up {
            color: $brand-alternative-2;
            display: block;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @include media-breakpoint-up(md) {
                font-size: 26px;
            }
        }

        .title-down {
            color: $brand-alternative-2;
            display: block;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @include media-breakpoint-up(md) {
                font-size: 26px;
            }

            img {
                width: 53px;

                @include media-breakpoint-up(md) {
                    width: 62px;
                }
            }
        }

        .name {
            display: block;
            text-align: center;
            color: $brand-alternative-2;
            font-size: 35px;
            font-style: italic;
            font-weight: 300;
            line-height: normal;

            @include media-breakpoint-up(md) {
                font-size: 46px;
            }
        }
    }
}

//.in-headband

.HomeBannerBox {
    .item {
        position: relative;

        .container {
            position: absolute;
            z-index: 1;
            top: 50%;
            padding: 0;
            line-height: normal;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(sm) {
                top: 42vh;
                left: 0;
                right: 0;
            }
            @include media-breakpoint-up(md) {
                top: 55%;
                left: 50%;
                transform: translate(-50%, -40%);
                -ms-transform: translate(-50%, -40%);
                bottom: unset;
            }

            .BannerCaption {
                color: white;
                position: relative;
                text-align: center;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }

                > label {
                    color: white;
                    display: block;
                    margin-bottom: 10px;
                    text-align: center;
                    transition: all 0.5s ease-out;
                    font-size: 80px;
                    text-shadow: 0px 0px 6px rgba(0,0,0,0.7);

                    @include media-breakpoint-up(md) {
                        min-height: 90px;
                        margin-bottom: 0;
                        font-size: 90px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 150px;
                    }

                }

                p {
                    text-align: center;
                    transition: all 0.5s ease-out;

                    span {
                        font-size: 22px;
                        font-weight: 300;
                        color: white;
                        display: block;
                        text-shadow: 0px 0px 6px rgba(0,0,0,0.7);

                        @include media-breakpoint-up(md) {
                            font-size: 34px;
                        }
                    }

                    label {
                        color: white;
                        display: block;
                        margin: 0;
                        font-size: 28px;
                        text-shadow: 0px 0px 6px rgba(0,0,0,0.7);

                        @include media-breakpoint-up(md) {
                            font-size: 44px;
                        }
                    }

                }

            }
        }
    }

}

//.HomeBannerBox

.in-happy-moments {
    padding-bottom: 100px;
    padding-top: 50px;
    background-image: url("/images/espiga.svg");
    background-size: 120px auto;
    background-repeat: no-repeat;
    background-position: 0 100%;

    @include media-breakpoint-up(md) {
        background-size: 190px auto;
    }

    .text {
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        @include media-breakpoint-up(md) {
            font-size: 28px;
            margin-left: 6%;
            width: 595px;
        }
        @include media-breakpoint-up(lg) {
            display: block;
            margin-left: 24%;
        }

        &.t-2 {
            text-align: right;

            @include media-breakpoint-up(md) {
                text-align: right;
                margin-left: 30%;
                width: 425px;
            }
            @include media-breakpoint-up(lg) {
                margin-left: 25%;
                width: 580px;
            }
        }

        &.t-3 {
            color: $brand-alternative;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 27.475px; /* 98.127% */
            margin-left: 40%;
            position: relative;
            width: 210px;

            @include media-breakpoint-up(md) {
                font-size: 36px;
                line-height: 36px;
                width: 300px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 54px;
                line-height: 50px;
                width: 500px;
            }

            .line {
                background-color: $brand-alternative;
                bottom: -10px;
                height: 2px;
                left: 0;
                position: absolute;
                right: 0;
                margin: 0 auto;
                width: 90%;

                @include media-breakpoint-up(md) {
                    width: 80%;
                }
            }
        }
    }
}

//.in-happy-moments

.in-40-years {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(250, 233, 220, 1) 50%, rgba(250, 233, 220, 1) 100%);
    padding-bottom: 100px;
    padding-top: 20px;

    @include media-breakpoint-up(md) {
        padding-top: 120px;
    }

    .main-back {
        background-image: url("/images/back-cactus-xs.webp");
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: 0 0;

        @include media-breakpoint-up(md) {
            background-image: url("/images/back-cactus.webp");
            background-position: 0 20%;
        }

        .wrap-title {
            position: relative;

            .title {
                display: flex;
                font-size: 120px;
                align-items: center;
                justify-content: center;
                line-height: 25px;
                width: 100%;

                @include media-breakpoint-up(md) {
                    font-size: 210px;
                    position: relative;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    max-width: 700px;
                    margin: 0 auto;
                }

            }

            .clouds {
                background-image: url("/images/nube.webp");
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: 0 0;
                position: absolute;
                top: -85px;
                left: 0;
                height: 330px;
                width: 100%;

                @include media-breakpoint-up(md) {
                    background-image: url("/images/nubes.webp");
                    height: 185px;
                    top: 0;
                }
            }
        }

        .wrap-cactus {

            @include media-breakpoint-up(md) {
                background-image: url("/images/espiga-3.svg");
                background-repeat: no-repeat;
                background-position: right 110%;
                background-size: 240px auto;
            }

            img {
                display: block;
                margin: 0 auto 100px;
                width: 240px;

                @include media-breakpoint-up(md) {
                    margin-top: -21px;
                    position: relative;
                    z-index: 3;
                    width: 30%;
                }
            }

            .title {
                font-size: 100px;

                @include media-breakpoint-up(md) {
                    font-size: 160px;
                }
            }
        }

        .wrap-count-down {
            display: flex;
            align-items: center;
            justify-content: space-around;

            @include media-breakpoint-up(md) {
                margin: 0 auto;
                width: 480px;
            }

            .item {
                position: relative;
                width: 50px;

                @include media-breakpoint-up(md) {
                    width: 70px;
                }

                &.ll {
                    &:after {
                        background-color: #F2B485;
                        left: -25px;
                        top: 0;
                        position: absolute;
                        content: "";
                        height: 100%;
                        width: 2px;
                    }
                }

                label {
                    color: #838180;
                    display: block;
                    text-align: center;
                    font-size: 24.471px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 35.986px; /* 147.059% */

                    @include media-breakpoint-up(md) {
                        font-size: 34px;
                    }

                    &.big {
                        font-weight: 700;
                        font-size: 38px;

                        @include media-breakpoint-up(md) {
                            font-size: 54px;
                        }
                    }
                }
            }
        }
    }

}

//.in-40-years

.in-address {

    @include media-breakpoint-up(md) {
        margin: 0 auto 0;
        max-width: 900px;
        width: 90%;
    }

    .block {
        background-color: white;
        position: relative;
        height: 100px;
        margin: -20px auto 0;
        width: 80%;

        @include media-breakpoint-up(md) {
            margin: -30px auto 0;
            width: 90%;
        }
        @include media-breakpoint-up(lg) {
            left: -100px;
            width: 1100px;
        }
    }

    .wrap-address {
        background: rgba(54, 41, 24, 0.60);
        bottom: 0;
        left: 0;
        padding-bottom: 34px;
        padding-top: 34px;
        position: absolute;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 230px;
        }

        label {
            color: white;
            font-size: 22px;
            font-style: normal;
            display: block;
            font-weight: 300;
            line-height: 26px;
            text-align: center;

            strong {
                font-weight: 700;
            }

            &.address {
                strong {
                    display: block;
                }
            }
        }

        a {
            border: 0;
            background: #926C50;
            border-radius: 23.842px;
            font-family: $fontBase;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            display: block;
            margin: 20px auto 0;
            position: relative;
            padding-left: 17%;
            padding-top: 9px;
            width: 200px;

            img {
                position: absolute;
                top: 5px;
                left: 20px;
            }
        }
    }

    .wrap-codigo {
        background: rgba(146, 108, 80, 0.90);
        bottom: 0;
        left: 0;
        padding-bottom: 34px;
        padding-top: 34px;
        position: absolute;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 230px;
        }

        label {
            color: white;
            font-size: 22px;
            font-style: normal;
            display: block;
            font-weight: 300;
            line-height: 26px;
            text-align: center;

            strong {
                font-weight: 700;
            }
        }

        .wrap-cod-vest {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 70px;

                &:last-child {
                    margin-left: 20px;
                    width: 63px;
                }
            }
        }

    }
}

//.in-address

.in-gallery {
    margin-bottom: 80px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    //max-width: 1440px;
}

//.in-gallery
.in-contact {
    background-color: #FFF9F4;
    background-image: url('/images/back-contact-xs.webp'), url('/images/textura-contact.webp');
    background-size: 90% auto, 50% auto;
    background-repeat: no-repeat;
    background-position: 0 bottom, right bottom;
    border-bottom: 16px solid #F9D5B8;
    padding-bottom: 480px;
    padding-top: 80px;
    position: relative;

    @include media-breakpoint-up(md) {
        background-size: 390px auto, auto 100%;
    }
    @include media-breakpoint-up(lg) {
        background-image: url('/images/back-contact.webp'), url('/images/textura-contact.webp');
        background-size: 565px auto, auto 100%;
        padding-bottom: 80px;
    }

    .title {
        @include media-breakpoint-up(lg) {
            font-size: 106px;
        }
    }

    .espiga {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -69%;

        @include media-breakpoint-up(lg) {
            bottom: -20%;
        }
        @include media-breakpoint-up(xl) {
            right: 25%;
        }
    }

    form {
        position: relative;
        z-index: 3;

        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            width: 670px;
        }

        input {
            background-color: transparent;
            border-radius: 24px;
            border: 1px solid #9B8B76;
            display: block;
            height: 55px;
            margin: 0 auto 40px;
            text-align: center;
            width: 300px;

            &.error {
                border-color: $brand-error;
            }
        }

        textarea {
            background-color: transparent;
            border-radius: 24px;
            border: 1px solid #9B8B76;
            display: block;
            height: 195px;
            margin: 0 auto 40px;
            text-align: left;
            padding: 20px;
            width: 300px;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }

        a {
            background-color: #926C50;
            color: white;
            font-family: $fontBase;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 52px;
            display: block;
            margin: 50px auto 40px;
            text-transform: capitalize;
            width: 300px;

            strong {
                font-weight: 700;
            }
        }

        .wrap-buttons {

            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            button {
                background-color: #926C50;
                color: white;
                font-family: $fontBase;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 52px;
                display: block;
                margin: 0 auto 10px;
                text-transform: inherit;
                width: 250px;

                &.no-ok {
                    background-color: $brand-third;
                }
            }
        }
    }
}

//.in-contact

.slick-niria {
    position: relative;

    .item {
        image {
            height: auto;
            width: 90%;

            @include media-breakpoint-up(md) {
                width: 600px;
            }
            @include media-breakpoint-up(lg) {
                width: 800px;
            }
            @include media-breakpoint-up(xl) {
                width: 1200px;
            }
        }
    }
}

.arrows-slider {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -40%);
    -ms-transform: translate(-50%, -40%);
    bottom: unset;
    width: 110%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    .prev {
        background-image: url("/images/arrow.webp");
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: 0 0;
        height: 45px;
        position: relative;
        transform: rotate(180deg);
        width: 25px;
    }
    .next {
        background-image: url("/images/arrow.webp");
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: 0 0;
        height: 45px;
        position: relative;
        width: 25px;
    }
}

.swal2-close {
    font-size: 55px;
    color: #f2b485;
}
.swal2-html-container{
    height: auto;
    margin: 0 !important;
    width: 100%;
}
.swal2-modal {
    height: auto;
    padding: 0;
    width: 1200px;
}

.wrap-audio {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 15px;
    padding: 11px 16px 16px 16px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    height: 120px;
    width: 100px;
    z-index: 4;


    @include media-breakpoint-up(md) {
        padding: 20px;
        right: 50px;
        bottom: 100px;
        height: 140px;
    }

    img {
        cursor: pointer;
        position: relative;
    }

    label {
        color: #9B8B76;
        text-align: center;
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: relative;
    }
}