.bg-in-blue-500 {
    background-color: #053456;
}

.bg-hover-in-blue-500 {
    &:hover {
        background-color: #053456;
    }
}

.bg-in-yellow-500 {
    background-color: #fec42e;
}

.bg-hover-in-yellow-500 {
    &:hover {
        background-color: #fec42e;
    }
}

.title {
    color: $brand-secondary;
    font-family: $fontTitle;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;

    @include media-breakpoint-up(lg) {
        font-size: 150px;
    }
}

.text {
    color: $brand-primary;
    text-align: center;
    font-family: $fontBase;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include media-breakpoint-up(lg) {
        font-size: 44px;
    }
}
